.blog {
    padding: 80px 20px 20px 20px; /* Adjust the top padding as needed */
  }
  
  .blog h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-post {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .blog-post h2 {
    margin-bottom: 5px;
  }
  
  .blog-date {
    color: #999;
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .blog-content {
    padding-left: 300px;
    padding-right: 300px;
  }
  