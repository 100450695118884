.header {
  background: url('your-image.jpg') no-repeat center center/cover;
  color: white;
  padding: 100px 0;
  text-align: center;
}

.header-content {
  max-width: 600px;
  margin: 0 auto;
}

.header h1 {
  font-size: 3em;
}

.header p {
  font-size: 1.5em;
}
