.skills {
  background: #242424; /* Dark section background */
  padding: 60px 20px;
  color: white;
  text-align: center;
}

.skills-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.skill-box {
  flex: 1 1 30%; /* Flex-grow: 1, Flex-shrink: 1, Flex-basis: 30% */
  background-color: #36454F;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 150px; /* Ensures boxes don't get too small */
  min-height: 100px;
  box-sizing: border-box;
  display: flex; /* Add Flexbox to center content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  text-align: center; /* Center text within the box */
}

@media (max-width: 768px) {
  .skill-box {
    flex: 1 1 45%; /* Adjust size for smaller screens */
  }
}

@media (max-width: 480px) {
  .skill-box {
    flex: 1 1 100%; /* Stack boxes vertically on very small screens */
  }
}
