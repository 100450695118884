.navbar {
  position: fixed;
  width: 100%;
  background: #333333; /* Dark navbar background */
  color: white;
  padding: 1em;
  display: flex;
  justify-content: center;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1em;
  margin: 0;
  padding: 0;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar .home-link {
  font-weight: bold;
  color: white;

  padding: 10px 20px;
  border-radius: 5px;
  text-transform: uppercase; /* Optional: makes the text uppercase */
}

.navbar .blog-link {
  font-weight: bold;
  color: white;

  padding: 10px 20px;
  border-radius: 5px;
  text-transform: uppercase; /* Optional: makes the text uppercase */
}
