.experience {
  padding: 20px;
  background: #1e1e1e; /* Dark section background */
  color: white;
  text-align: center;
}

.experience h2 {
  margin-bottom: 20px;
}

.experience-item {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px; /* Adjust as necessary */
  margin: 0 auto;
  position: relative;
}

.experience-image {
  max-width: 200px;
  height: auto;
  border-radius: 8px;
  position: absolute;
  left: -150px; /* Adjust as necessary to position image */
}

.experience-content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.experience-content {
  max-width: 800px; /* Adjust max-width as needed */
  text-align: center; /* Center text within the content box */
}
